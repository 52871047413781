import React from 'react';
import './About.css';


const About = () => {
    const lang = ["Java", "Python", "JavaScript", "HTML", "CSS", "C"];
    const frameworks = ["React", "Spring", "Selenium", "JUnit", "Robot Framework", "SQL", "Jenkins", "Pandas", "AWS", "Azure"];
    return (
        <div className="about-div">
            <div className="about-title">
                About Me
            </div>
            <div className="about-content-container">
                <div className="about-content-image">
                    <img className="about-pic" src="circle-Bhala.png"/>
                </div>
                <div className="about-content-description">
                    <p>
                        I am currently pursuing a BASc. in <span className="text-blue-i">Computer Engineering </span>
                        at the University of Ottawa. I am a <span className="text-blue">motivated developer </span>
                        and <span className="text-blue">problem solver </span> with experience
                        in both front-end and back-end development. <br /> <br />
                        I am always looking to develop my skills and learn about new technologies. I would say I am someone
                        who works well with others and quick to adapt to new challenges. I am highly interested in DevOps and Project Management. <br /> <br />
                        In my free time, you can find me playing tennis or basketball, watching some Netflix, or reading up
                        on latest stock market news. <br /> <br />

                    </p>
                    <div className="about-skills-container">
                        <p className="subheading-skill">My Skills</p>
                        <div className="about-skills-content">
                            <p className="subheading">Programming Languages:</p>
                            <div>
                                {lang.map(skill => {
                                    return (
                                        <div className="chips orange">
                                            {skill}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="about-skills-content">
                            <p className="subheading">Frameworks and Technologies:</p>
                            <div>
                                {frameworks.map(skill => {
                                    return (
                                        <div className="chips green">
                                            {skill}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

};


export default About;