import React, {Component} from 'react';
import {About, Contact, Experience, Header, Home, Projects} from './Components';
import './App.css';
import './Components/Header.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    componentDidMount() {
        window.onscroll = function () {
            if (window.pageYOffset < 5) {
                document.getElementById("header").className = "header";
            }
            else {
                document.getElementById("header").className = "header scroll";
            }
        };
    }

    componentWillUnmount() {
        window.onscroll = null;
    }

    render() {
        return (
            <div className="App">
                <Header className="header"/>
                <Home id="home" className="h2"/>
                <div id="about" className="div2">
                    <About />
                </div>
                <div id="experience" className="div2 ">
                    <Experience/>
                </div>
                <div id="projects" className="div2 ">
                    <Projects />
                </div>
                <div id="contact" className="div2">
                    <Contact/>
                </div>
            </div>
        );
    }
}

export default App;
