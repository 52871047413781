import React from 'react';
import {Link} from "react-scroll";
import './Header.css';


const Header = () => {
    const headerTabs = ["home", "about", "experience", "projects", "contact"];

    function openNav() {
        document.getElementById("overlay").style.width = "100%";
    }

    function closeNav() {
        document.getElementById("overlay").style.width = "0%";
    }

    return (
        <>
            <div id="header" className="header">
                <Link
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                >
                    <h1 className="logo" align="left">BM</h1>
                </Link>
                <div className="menu-icon" onClick={openNav}>
                    <div className="menu-icon-bar"></div>
                    <div className="menu-icon-bar"></div>
                    <div className="menu-icon-bar"></div>
                </div>

                <ul className="nav-items">
                    {headerTabs.slice(1).map(name =>
                        <li className={"nav-item"} key={name}>
                            <Link
                                activeClass="active"
                                to={name}
                                spy={true}
                                smooth={true}
                                offset={5}
                                duration={500}
                            >
                                {name}
                            </Link>
                        </li>
                    )}
                    <a href="Bhala_Malghan_Resume.pdf" download className="resume-link">
                        <li className="nav-item resume" key="resume">
                            Resume
                        </li>
                    </a>


                </ul>
                <div id="overlay" className="overlay">
                    <a className="close" onClick={closeNav}>&times;</a>
                    <div className="overlay-content" onClick={closeNav}>
                        {headerTabs.map(name =>
                            <Link
                                key={name}
                                to={name}
                                spy={true}
                                smooth={true}
                                offset={0}
                                delay={500}
                                duration={1000}
                                onClick={closeNav}
                            >
                                {name}
                            </Link>)}
                        <a href="Bhala_Malghan_Resume.pdf" download >
                            resume
                        </a>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Header;