import React from "react";
import {Paper} from "@material-ui/core";
import "./Experience.css";

const Experience = () => {
    const ford = {
        key: "ford",
        role: "Full Stack Developer",
        company: "Ford Motor Company",
        date: "Jan 2020 - Apr 2020",
        description: ["Collaborated in the development for Ford’s Analytics and Diagnostics Platform",
            "Utilized Java, KnockoutJS, SQL, and RabbitMQ across the development of features and bug fixes",
            "Leveraged REST APIs and Spring Actuators in the implementation of usability features that significantly improved productivity"
        ],
        logo: "ford.png"
    };
    const ised = {
        key: "goc",
        role: "Software Developer",
        company: "Innovation, Science, and Economic Development Canada",
        date: "May 2019 - Aug 2019",
        description: ["Successfully conceptualized and deployed an open source web application, Skillhub, as part of the CDH Studio program",
            "Designed and developed front-end UI components using React, HTML5, and SASS; Redux for state management",
            "Developed backend features using NodeJs, Express, and Feathers to interface PostgreSQL"
        ],
        logo: "goc.png"
    };

    const schenker = {
        key: "dbs",
        role: "IT Developer Intern",
        company: "DB Schenker",
        date: "May 2018 - Aug 2018",
        description: ["Backend-development for DB Schenker’s Customs product in Java using technologies such as Spring, JDBC, and Hibernate",
            "Automated UI testing with Selenium Webdriver and deployed with Jenkins in order to streamline the testing process ",
            "Developed Python scripts utilizing Pandas API to analyze server logs and performance data to identify issues in order to increase efficiency"
        ],
        logo: "dbs.png"
    };

    const exp = [ford, ised, schenker];
    const rossTech1 = ["Java", "Robot Framework", "JavaSwing", "Jenkins", "ActiveMQ"];
    const rossTech2 = ["Java", "Python", "OWASP", "Jenkins", "Tenable"];
    const fordTech = ["Java", "Spring", "SQL", "JavaScript", "RabbitMQ"];
    const isedTech = ["Javascript", "React", "HTML", "SASS", "Redux", "NodeJS", "PostgreSQL", "Product Design"];
    const dbTech = ["Java", "Python", "Spring", "MySQL", "Selenium", "JDBC"];
    return (
        <>
            <div className="experience-title">
                Experience
            </div>
            <div className="container">
                <div class="experience-div">
                    <div class="timeline">
                    <div class="timeline-wrapper">
                            <div class="timeline-yr">
                                <span>2021</span>
                            </div>
                            <div class="timeline-info">
                                <h3><span>Software Developer (Co-op)</span><small>Ross Video</small></h3>
                                <p>Developed back-end features and security automation for Ross's Production Automation Platform, OverDrive</p>
                                <tiny>Skills Developed:</tiny>
                                <div className="tool-container">
                                    {rossTech2.map(skill => <div className="timeline-chips">{skill}</div>)}
                                </div>
                            </div>
                        </div>

                        <div class="timeline-wrapper">
                            <div class="timeline-yr">
                                <span>2020</span>
                            </div>
                            <div class="timeline-info">
                                <h3><span>Software Developer (Co-op)</span><small>Ross Video</small></h3>
                                <p>Developed back-end features and test automation for Ross's Production Automation Platform, OverDrive</p>
                                <tiny>Skills Developed:</tiny>
                                <div className="tool-container">
                                    {rossTech1.map(skill => <div className="timeline-chips">{skill}</div>)}
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />

                            <div class="timeline-info">
                                <h3><span>Full Stack Developer (Co-op)</span><small>Ford Motor Company</small></h3>
                                <p>Developed features and bug fixes for Ford’s Analytics and Diagnostics Platform.</p>
                                <tiny>Skills Developed:</tiny>
                                <div className="tool-container">
                                    {fordTech.map(skill => <div className="timeline-chips">{skill}</div>)}
                                </div>
                            </div>
                        </div>

                        <div className="timeline-wrapper">
                            <div className="timeline-yr">
                                <span>2019</span>
                            </div>
                            <div className="timeline-info">
                                <h3><span>Software Developer (Co-op)</span><small>ISED Canada</small></h3>
                                <p>Conceptualized and deployed an open source web application, <em>Skillhub</em>, in a team of coops
                                    as part of the CDH Studio Program</p>
                                <tiny>Skills Developed:</tiny>
                                <div className="tool-container">
                                    {isedTech.map(skill => <div className="timeline-chips">{skill}</div>)}
                                </div>
                            </div>
                        </div>

                        <div class="timeline-wrapper">
                            <div class="timeline-yr">
                                <span>2018</span>
                            </div>
                            <div class="timeline-info">
                                <h3><span>IT Developer Intern</span><small>DB Schenker</small></h3>
                                <p>Developed back-end features and UI test automation for DB Schenker’s Customs product </p>
                                <tiny>Skills Developed:</tiny>
                                <div className="tool-container">
                                    {dbTech.map(skill => <div className="timeline-chips">{skill}</div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Experience;