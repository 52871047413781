import React from "react";
import "./Home.css"
import {Link} from "react-scroll";


const Home = () => {
    return (
        <div className="home">
            <div className="text-div">
                <h1 className="hi-text">hi there!</h1>
                <h2 className="name-text">i'm <em>bhala</em>chandra malghan</h2>
            </div>
            <Link
                to={"about"}
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
            >
                <i className="arrow down"></i>
            </Link>
        </div>

    );
};


export default Home;