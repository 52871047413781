import React from "react";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import "./Projects.css";


const Projects = () => {
    return (
        <div className="projects-div">
            <div className="projects-title">
                Projects
            </div>
            <div className="cards-container">
                <Card className="project-card">
                    <CardActionArea>
                        <CardMedia
                            className="project-pic"
                            title="Contemplative Reptile"
                        >
                            <img className="quickpass" src="quickpass.png"/>
                        </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                QuickPass
                            </Typography>
                            <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                                Point of entry screening device to enforce COVID-19 Protocols and Analytics Dashboard for Business Intelligence. 
                            </Typography>
                            <Typography variant="body1" component="p">
                                <tiny>Built with NodeJS, Express, Python, RaspberryPi, and Azure</tiny>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <a className="gitlink" href="https://github.com/Capstone-QuickPass" target="_blank">
                            <Button size="small" color="primary">
                                Github
                            </Button>
                        </a>
                    </CardActions>
                </Card>
                <Card className="project-card">
                    <CardActionArea>
                        <CardMedia
                            className="project-pic"
                        >
                            <img className="instafix" src="instafix.png"/>
                        </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                InstaFix
                            </Typography>
                            <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                                Android app that offers on-demand services for home owners by connecting them to service
                                providers.
                            </Typography>
                            <Typography variant="body1" component="p">
                                <tiny>Built with Android Studio, Java, and Firebase</tiny>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <a className="gitlink" href="https://github.com/bmalghan/InstaFix" target="_blank">
                            <Button size="small" color="primary">
                                Github
                            </Button>
                        </a>
                    </CardActions>
                </Card>
                <Card className="project-card">
                    <CardActionArea>
                        <CardMedia
                            className="project-pic"
                        >
                            <img className="instant" src="instant.png"/>
                        </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Instant Insanity
                            </Typography>
                            <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                                Program to find the solution to the puzzle using search
                                algorithms such as breadth-first and generate-and-test
                            </Typography>
                            <Typography variant="body1" component="p">
                                <tiny>Built with Java</tiny>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <a className="gitlink" href="https://github.com/bmalghan/InstantInsanity" target="_blank">
                            <Button size="small" color="primary">
                                Github
                            </Button>
                        </a>
                    </CardActions>
                </Card>
            </div>
        </div>
    );
};

export default Projects;