import React from "react";
import "./Contact.css";


const Contact = () => {
    return (
        <div className="contact-div">
            <div className="contact-title">
                CONTACT
            </div>
            <div className="contact-line">
                Like what you see?
                Let's chat! &#128512;
            </div>
            <div className="contact-info">
                <a className="contact-info-line" href="http://linkedin.com/in/bmalghan" target="_blank">
                    <img src={require("../assets/linkedin.svg")} className="contact-logo" align="center"/>
                </a>
                <a className="contact-info-line" href="http://github.com/bmalghan" target="_blank">
                    <img src={require("../assets/github.svg")} className="contact-logo" align="center"/>
                </a>
                <a className="contact-info-line" href="mailto:amalghan5@gmail.com" target="_blank">
                    <img src={require("../assets/email.svg")} className="contact-logo" align="center"/>
                </a>
            </div>

            <p className="copyright">Designed and Developed by Bhalachandra Malghan </p>
        </div>

    );
};

export default Contact;